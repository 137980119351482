import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple } from "../components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugifyPost from "../../slugifyPost"

const SingleBookPage = ({ data, pageContext }) => {
  const {
    // contentful_id,
    bookTitle,
    bookText,
    author,
    publisher,
    bookYear,
    bookSize,
  } = data.contentfulBook

  const image = getImage(data.contentfulBook.bookImage)

  const { previous, next } = pageContext

  const prevTitle = previous ? previous.bookTitle : null
  const nextTitle = next ? next.bookTitle : null
  // const prevRow = previous ? previous.contentful_id : null
  // const nextRow = next ? next.contentful_id : null
  // az id-vel túl csúnya link, azért csináltam a névvel inkább
  const prevRow = previous ? slugifyPost(previous.author.authorFullName) : null
  const nextRow = next ? slugifyPost(next.author.authorFullName) : null
  const allItemPage = `/translation/books/1`
  const oneItemPage = `/translation/book/`

  // console.log("author", author)

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h1>{bookTitle}</h1>
          <h2>{author.authorFullName}</h2>

          <div
            className="info"
            dangerouslySetInnerHTML={{
              __html: bookText.childMarkdownRemark.html,
            }}
          ></div>

          {/* <a href={books_moly} target="_blank" rel="noreferrer" className="btn">
            <h3>moly.hu: {books_title}</h3>
          </a> */}

          <h4 className="publisher">{publisher.publisherName}</h4>
          <h4 className="date-page">
            {bookYear.bookYear} | {bookSize} oldal
          </h4>

          <GatsbyImage image={image} className="img" alt="Book cover" />
        </div>
        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-5);
  padding-top: 9rem;
  padding-bottom: 0rem;
  color: var(--clr-primary-4);

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      padding-top: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
    }

    .publisher {
      font-weight: bold;
    }

    .date-page {
      text-transform: lowercase;
      padding-bottom: 2rem;
      font-weight: bold;
    }

    .info {
      padding-left: 10%;
      padding-bottom: 2rem;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }

  .img {
    border-radius: var(--radius);
    transition: var(--transition);
    width: 300px;
    margin: 0 auto;
  }

  a {
    ${"" /* display: block; */}
    width: auto;
    text-align: center;
    ${"" /* padding: 1rem 1rem; */}
    margin: 0 auto;
    ${"" /* margin-top: 1rem; */}
    color: var(--clr-light-3);
  }

  .btn {
    color: var(--clr-primary-22);
  }

  .btn:hover {
    color: var(--clr-primary-1);
  }

  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    contentfulBook(contentful_id: { eq: $recordId }) {
      contentful_id
      bookTitle
      author {
        authorFullName
      }
      publisher {
        publisherName
      }
      bookYear {
        bookYear
      }
      bookSize
      bookText {
        childMarkdownRemark {
          html
        }
      }
      bookImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`

export default SingleBookPage
